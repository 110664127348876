import React from "react";
import { graphql } from "gatsby";
import styled from "styled-components";
import ButtonText from "../buttons/ButtonText";
import SectionTitle from "../SectionTitle";
import GridContainer from "../GridContainer";
import { breakpoints, colors } from "../../styles/variables";
import imgChar from "../../assets/images/media/media-char.png";
import imgLeaves from "../../assets/images/media/media-leaves.png";
import imgLeavesMobile from "../../assets/images/media/media-leaves-mobile.png";

const Section = styled.section`
  position: relative;
  background-color: ${colors.darkGreen};
  padding-bottom: clamp(54px, 11vw, 125px);

  .imageChar {
    width: 30%;
    max-width: 480px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -118%);
    pointer-events: none;
    z-index: 2;
  }
  .swirlWrapper {
    pointer-events: none;
    position: absolute;
    width: 100%;
    height: 100%;
    inset: 0;
    z-index: 2;
  }
  .imageLeaves {
    position: absolute;
    width: 50%;
    max-width: 700px;
    bottom: 0;
    right: 0;
    transform: translate(25%, 0);
  }
  .imageMobileLeaves {
    display: none;
  }
  .gridOut {
    height: 100%;
    position: relative;
  }
  .gridIn {
    grid-column: 4 / span 6;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .buttonRow {
    text-align: center;
  }
  .text-header {
    margin-bottom: 32px;
  }

  @media (max-width: ${breakpoints.tablet}) {
    .gridIn {
      grid-column: 3 / span 4;
    }
    .imageChar {
      top: 50%;
      transform: translate(-50%, -135%);
    }
    .imageLeaves {
      display: none;
    }
    .imageMobileLeaves {
      display: block;
      position: absolute;
      width: 90%;
      bottom: 0;
      right: 0;
    }
  }
  @media (max-width: ${breakpoints.mobile}) {
    .gridOut {
      padding-top: 50px;
      padding-bottom: 50px;
    }
    .gridIn {
      grid-column: 1 / span 2;
    }
    .imageChar {
      width: 60%;
      top: 0%;
      transform: translate(-50%, -60%);
    }
    .imageMobileLeaves {
      width: 150%;
      bottom: -7%;
    }
  }
`;

const SVGLightningLines = styled.svg`
  display: block;
  position: absolute;
  left: 0;
  top: -22vw;
  width: 100%;
  height: auto;
  z-index: 1;
`;

const data = {
  title: "Media Inquiries",
  description:
    "In quis mi rhoncus, euismod neque non, tristique nisl. Maecenas porta dui in justo cursus, lacinia tincidunt odio auctor. Fusce quam velit, finibus at lorem vitae, ornare tempor nulla. ",
  button_text: "Contact us",
  click_url: "https://www.google.com",
};

export const fragment = graphql`
  fragment MediaContactFragment on Contentstack_bandle_tale_media_page {
    mediaContact: media_page_contact_section {
      alignment
      headline
      bodyCopy: body_copy
      ctaLink: cta_link {
        title
        href
      }
    }
  }
`;

export interface MediaInquiriesData {
  alignment: string;
  headline: string;
  bodyCopy: string;
  ctaLink: {
    title: string;
    href: string;
  };
}

interface Props {
  data: MediaInquiriesData;
}

const MediaInquiries = (props: Props) => {
  const { alignment, headline, bodyCopy, ctaLink } = props.data;

  return (
    <Section>
      <div className="swirlWrapper">
        <img className="imageLeaves" src={imgLeaves} />
        <img className="imageMobileLeaves" src={imgLeavesMobile} />
      </div>
      <GridContainer className="gridOut">
        <img className="imageChar" src={imgChar} />

        <div className="gridIn">
          <SectionTitle colorTheme="green-white" title={headline} hideImageDivider={true} sectionType="media-inquiry">
            {bodyCopy}
          </SectionTitle>
          <div className="buttonRow">
            <ButtonText
              colorTheme="border-grey-green"
              callback={() => {
                window.open(ctaLink.href, "_new");
              }}
            >
              {ctaLink.title}
            </ButtonText>
          </div>
        </div>
      </GridContainer>
      <SVGLightningLines
        fill="none"
        height="398"
        viewBox="0 0 1440 398"
        width="1440"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M2661.8 396.6L1039 160L1055.5 250L-565 9" stroke="#F9E5A9" />
        <path d="M2664.8 389.6L1045.3 153L1061.8 243L-565.5 1" stroke="#F9E5A9" />
      </SVGLightningLines>
    </Section>
  );
};

export default MediaInquiries;
