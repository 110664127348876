import React from "react";
import { PageProps, graphql } from "gatsby";
import { useI18next } from "gatsby-plugin-react-i18next";
import RiotBar from "@riotgames/riotbar-react";
import Seo, { SeoData } from "../components/Seo/Seo";

import MediaHero, { MediaHeroData } from "../components/MediaPage/MediaHero";
import MediaGallery, { MediaGalleryData } from "../components/MediaPage/MediaGallery";
import MediaInquiries, { MediaInquiriesData } from "../components/MediaPage/MediaInquiries";
import VideoOverlay from "../components/VideoOverlay";
import { useSiteMetadata } from "../hooks/use-site-metadata";

import { StyleSheetManager } from "styled-components";
import griddie from "styled-griddie";
import { navigate } from "@reach/router";

interface PageData {
  media: {
    downloadString: string;
    mediaSeo: SeoData;
    mediaMarquee: MediaHeroData;
    mediaItems: MediaGalleryData[];
    mediaContact: MediaInquiriesData;
  };
}

interface Props extends PageProps<PageData> {}

const MediaPage: React.FC<Props> = ({ data }) => {
  const [videoOpen, setVideo] = React.useState(false);

  const { mediaSeo, mediaMarquee, mediaItems, mediaContact, downloadString } = data.media;

  const { siteDomain } = useSiteMetadata();
  const { language } = useI18next();

  const ytVideoUrl = mediaMarquee.youtubeEmbedLink.href;
  // https://stackoverflow.com/a/8260383/2316591
  const ytVideoCode = ytVideoUrl.match(/.*(?:youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=)([^#\&\?]*).*/)?.[1] || "";

  const mediaUrl = `https://${siteDomain}/${language}/media/`;

  const riotbarEnv = process.env.GATSBY_RIOTBAR_ENVIRONMENT === "staging" ? "staging" : "prod";

  return (
    <StyleSheetManager stylisPlugins={[griddie]}>
      <main lang={language} className={`lang-${language}`}>
        <Seo data={mediaSeo} url={mediaUrl} locale={language} />
        <RiotBar product="forge-hiddenpath" locale={language} environment={riotbarEnv} />
        <MediaHero
          data={mediaMarquee}
          videoOpen={() => {
            setVideo(true);
          }}
        />
        <MediaGallery data={mediaItems} downloadString={downloadString} />
        <MediaInquiries data={mediaContact} />
        {videoOpen && (
          <VideoOverlay
            ytVideoCode={ytVideoCode}
            callback={() => {
              setVideo(false);
            }}
          />
        )}
      </main>
    </StyleSheetManager>
  );
};

export default MediaPage;

export const query = graphql`
  query ($language: String!) {
    media: contentstackBandleTaleMediaPage(locale: { eq: $language }) {
      downloadString: download_string
      ...MediaSeoFragment
      ...MediaMarqueeFragment
      ...MediaItemsFragment
      ...MediaContactFragment
    }
    ...TranslationQueryFragment
  }
`;
