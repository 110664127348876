import { colors } from "../../styles/variables";

export const SelectStyles = (isDark: boolean) => ({
  container: (provided: any, state: { isDisabled: boolean }) => ({
    ...provided,
    pointerEvents: state.isDisabled ? null : null,
  }),
  control: (provided: any, state: { isDisabled: boolean }) => ({
    ...provided,
    ...(isDark
      ? {
          borderColor: colors.lightGreen,
          backgroundColor: colors.lightGreen,
          "&:hover": {
            borderColor: "#30353F",
          },
        }
      : {
          borderColor: colors.lightGreen,
          backgroundColor: state.isDisabled ? "#FBFBFB" : colors.lightGreen,
          "&:hover": {
            borderColor: colors.lightGreen,
          },
        }),
    boxShadow: "none",
    borderRadius: "0px",
    cursor: state.isDisabled ? "not-allowed" : "pointer",
    borderWidth: "1px",
    paddingLeft: "24px",
    paddingRight: "24px",
    paddingTop: "16px",
    paddingBottom: "14px",
    fontSize: "16px",
    fontFamily: "ProximaNova-Bold",
    // color: "red",
  }),
  singleValue: (provided: any) => ({
    ...provided,
    ...(isDark
      ? {
          color: "#FFFFFF",
        }
      : {
          color: colors.darkGreen,
        }),
  }),
  valueContainer: (provided: any) => ({
    ...provided,
    padding: 0,
    height: "24px",
  }),
  input: (provided: any) => ({
    ...provided,
    height: "19px",
    overflow: "hidden",
  }),
  placeholder: (provided: any, state: { isDisabled: boolean }) => ({
    ...provided,
    ...(isDark
      ? {
          color: "#FFFFFF",
        }
      : {
          color: state.isDisabled ? "#BFBFBF;" : colors.darkGreen,
        }),
    fontSize: "16px",
    fontFamily: "ProximaNova-Bold",
    lineHeight: "24px",
  }),
  option: (provided: any) => ({
    ...provided,
    backgroundColor: colors.lightGreen,
    color: colors.white,
    fontSize: "16px",
    fontFamily: "ProximaNova-Bold",
    lineHeight: "24px",

    "&:hover": {
      color: colors.white,
      backgroundColor: colors.darkGreen,
      cursor: "pointer",
    },
  }),
  dropdownIndicator: (base: any) => ({
    ...base,
    ...(isDark
      ? {
          color: colors.white,
        }
      : {
          color: colors.black,
        }),
    padding: "0px",
    svg: {
      width: "18px",
      height: "18px",
    },
  }),
  indicatorSeparator: (base: any) => ({
    ...base,
    display: "none",
  }),
  menu: (base: any) => ({
    ...base,
    backgroundColor: colors.lightGreen,
    borderRadius: "0px",
    marginTop: "4px",
  }),
});
